@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@700&display=swap');

.home-page {
		margin: 0 auto;
		font-family: 'Raleway', sans-serif;
		background-color: white;
		background-image: url(../../../public/homepage-bg.jpg);
		background-size: cover;
		position: relative;
		overflow: hidden;

		.grayline {
			text-align: center;

			.divider {
				height: 2px;
				background: linear-gradient(to left, white, #333, white);
			}
		}

		.main-content {
			.main {
				height: 100vh;
				width: 100vw;
				background-color: white;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				opacity: 0;
				animation: fadeIn 2s forwards, slideIn 5s ease-in-out 3s infinite;
			
				.main-title {
					margin: 0px auto 50px;
					font-size: 4rem;
					font-weight: bold;
				}

				.main-subtitle {
					margin: 50px auto;
					font-size: 2rem;
					font-weight: bold;
				}

				button {
					margin: 50px auto;
					width: 200px;
					height: 80px;
					font-size: 20px;
					border: none;
					padding: 10px 20px; 
					border-radius: 5px; 
					cursor: pointer;
					box-shadow: 0 0 15px 0 rgba(40, 46, 117, 0.9);
				}

				button:hover {
					box-shadow: 0 0 2px 5px rgba(40, 46, 117, 0.7);
					transition: all 0.3s ease;
				}
			}

			.blueline {
				text-align: center;
				.divider {
					height: 2px;
					background: linear-gradient(to left, white, blue, white);
				}
			}
  
			.popup {
				position: fixed;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background-color: rgba(0, 0, 0, 0.8);
				border-radius: 5px;
			}
		}

		.no-scroll {
			overflow: hidden;
			height: 100%;
		}
  
		@keyframes fadeIn {
			to { opacity: 1; }
		}

		@keyframes slideIn {
			0%, 100% {
				opacity: 1;
			}
			50% {
				opacity: 0.85;
			}
		}
}

@media only screen and (max-width: 480px) {
  .home-page {
    .main-content .main {
		.main-title {
        	font-size: 2rem;
      	}
		.main-subtitle {
        	font-size: 1.5rem;
      	}
      	button {
        	width: 120px;
        	height: 50px;
        	font-size: 1rem;
      	}
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .home-page {
    .main {
      h1 {
        font-size: 14px;
      }
      p {
        font-size: 12px;
      }
      button {
        width: 150px;
        height: 60px;
        font-size: 16px;
      }
    }
  }

  .features-page {
    .top, .bottom {
      width: 95%;
    }
    .bottom {
      flex-direction: column;

      .right video {
        width: 100%;
        height: auto;
      }
    }
    .left, .right {
      width: 100%;
    }
    .text, h2, p {
      font-size: 1.5em;
    }
  }

  .testimonials-page {
    .testimonials-title {
      font-size: 2em;
      margin-bottom: 20px;
    }
    .feedback {
      p {
        font-size: 1.5em;
        margin: 10px 0;
      }
      .author {
        font-size: 1em;
      }
    }
  }
    .about-page {
      padding: 15px;
      .title, .subtitle {
        font-size: 2em;
        margin: 15px 0;
      }
      .content {
        width: 100%;
        font-size: 1em;
        padding: 0 15px;
        box-sizing: border-box;
        text-align: justify;
      }
    }
}