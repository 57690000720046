/*
.popup-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    font-size: 2.5rem;

    .popup-menu-content {
        position: absolute;
        width: 80%;
        text-align: center;
        color: white;

        .close-btn {
            top: 10px;
            right: 20px;
            cursor: pointer;
          }
    
        .nav-section {
            a {
                color: white;
                text-decoration: none;
            }
        }
    }
}
*/

@media screen and (max-width: 480px) {
    .dropdown-menu {
      border: 0.5px solid rgb(40, 46, 117);
      display: block;
      position: absolute;
      background-color: white;
      text-align: center;
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
      z-index: 1;
      width: 50%;
      right: 0;
      top: 80px;
      animation: slideDown 0.5s ease-out;

      .popup-menu-content {
        .nav-section {
            border: 0.5px solid rgba(40, 46, 117, 0.3);
            a {
                color: rgb(40, 46, 117);
                font-size: 1.25rem;
                text-decoration: none;
            }
        }
      }
    }
  
    @keyframes slideDown {
      from {
        opacity: 0;
        transform: translateY(-20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
}