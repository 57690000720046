.grayline {
  text-align: center;
  .divider {
    height: 2px;
    background: linear-gradient(to left, white, #333, white);
  }
}

.about-page {
  margin: 0 auto;
  text-align: center;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .about-video-background {
    position: relative;
    width: 100%;
    height: 70vh;
    overflow: hidden;
  
    video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: -1;
    }

    .about-page-content {
      margin: 0 auto;
      height: 50vh;
      width: 50vw;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      z-index: 1;
  
      .about-page-title {
        font-size: 2.5rem;
      }
      .about-page-subtitle {
        font-size: 1.125rem;
      }
    }
  }

  .about-page-team-members-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;

    .about-page-team-member-content {
      width: 90vw;
      .about-page-team-member-type {
        margin: 10px auto;
        font-size: 2.5rem;
      }

      .about-page-team-member-intro {
        display: flex;
        flex-wrap: wrap;

        .about-page-team-member {
          width: 250px;
          min-height: 200px;
          border: 2px solid #ddd;
          border-radius: 10px;
          margin: 10px auto;
          padding: 10px;
          text-align: center;
          transition: all 0.3s ease;
          &:hover {
            border-color: #007bff;
            transform: scale(1.05);
            .about-page-team-member-avatar {
              border-color: #007bff;
            }
          }
    
          .about-page-team-member-avatar {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            background-color: #ccc;
            margin: 0 auto 10px;
            border: 2px solid transparent;
            transition: border-color 0.3s ease;
            img {
              width: 100px;
              height: 100px;
              border-radius: 50%;
              margin: 0 auto 10px;
            }
          }
    
          .about-page-team-member-info {
            .about-page-team-member-name, .about-page-team-member-position {
              margin: 5px 0;
            }
          }
        }
    
        .about-page-team-member:hover .about-page-team-member-info::after {
          display: block;
          margin-top: 10px;
          font-size: 0.75rem;
          color: #666;
        }
      }
    }
  }
}

.blueline {
  text-align: center;
  .divider {
    height: 2px;
    background: linear-gradient(to left, black, blue, black);
  }
}

@media only screen and (max-width: 480px) {
  .about-page {
    .about-video-background {
      .about-page-content {
        height: 70vh;
        width: 60vw;
      }
    }
  }
}