@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');

.grayline {
    text-align: center;
    .divider {
      height: 2px;
      background: linear-gradient(to left, white, #333, white);
    }
  }

.testimonials-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    text-align: center;
    
    .testimonials-page-intro {
        margin: 0 auto;
        height: 60vh;
        width: 90vw;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        .testimonials-page-title {
            font-size: 4rem;
        }
        .testimonials-page-subtitle {
            font-size: 1.125rem;
        }
    }

    .testimonials-page-feedback {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        .testimonials-page-feedback-block {
            width: 33.3%;
            height: 40vh;
            border: 1px black solid;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: stretch;

            .testimonials-page-feedback-block-sign {
                img {
                        height: 100px;
                        width: 100px;
                        opacity: 0.25;
                    }
            }
            .testimonials-page-feedback-block-word {
                font-size: 1.75rem;
            }
            .testimonials-page-feedback-block-author {
                font-size: 1.125rem;
            }
        }
    }
}

.blueline {
    text-align: center;
    .divider {
      height: 2px;
      background: linear-gradient(to left, white, blue, white);
    }
}

@media only screen and (max-width: 480px) {
    .testimonials-page {
        height: 151vh;
        display: block;

        .testimonials-page-intro {
            .testimonials-page-title {
                font-size: 2.5rem;
            }
        }

        .testimonials-page-feedback {
            display: flex;
            flex-direction: column;

            .testimonials-page-feedback-block {
                height: 30vh;
                width: 90vw;
                .testimonials-page-feedback-block-sign {
                    img {
                        height: 80px;
                        width: 80px;
                    }
                }
            }
        }
    }
}