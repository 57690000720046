.hamburger-menu {
    background-color: #f0f0f0;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 300px;
    padding: 10px;
    border-right: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
  }
  
  .hamburger-menu.open {
    transform: translateX(0);
  }
  
//   .calendar-container {
//     margin-left: 320px;
//   }
  
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  li {
    padding: 10px 0;
    border-bottom: 1px solid #eee;
    cursor: pointer;
  }
  
  li:last-child {
    border-bottom: none;
  }
  
  li:hover {
    background-color: #e0e0e0;
  }
  