.features-page {
  margin: 10px auto;
  background-color: white;
  display: flex;
  flex-direction: column;

  .grayline {
    text-align: center;
    .divider {
      height: 2px;
      background: linear-gradient(to left, white, #333, white);
    }
  }

  .features-video {
    margin: 10px auto;
    video {
      width: 80vw;
      height: auto;
    }
  }

  .features-content {
    margin: 0 auto;
    width: 90vw;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;

    .features-content-left {
      height: 100vh;
      margin: 10px 30px;
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .features-content-left-title {
        font-size: 3rem;
      }
      .features-content-left-paragraph {
        font-size: 1.25rem;
      }

    }
    .features-content-right {
      margin: 10px 30px;
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .features-content-right-details {
        display: flex;
        flex-direction: column;

        .features-content-right-details-title {
          font-size: 1.5rem;
          margin-bottom: 50px;
        }

        .features-content-right-details-paragraph {
          font-size: 1rem;
        }
      }
    }
  }

  .blueline {
    text-align: center;
    .divider {
      height: 2px;
      background: linear-gradient(to left, white, blue, white);
    }
  }
}

@media only screen and (max-width: 480px) {
  .features-page {
    
    .features-content {
      display: flex;
      flex-direction: column;

      .features-content-left {
        width: 85%;
        height: 85vh;
        text-align: center;
        .features-content-left-title {
          font-size: 1.5rem;
          font-weight: bold;
        }
        .features-content-left-paragraph {
          font-size: 1rem;
        }
      }

      .features-content-right {
        width: 85%;
        height: 100vh;
        text-align: left;
        .features-content-right-details {
          margin: 5px auto;
        }
      }
    }
  }
}