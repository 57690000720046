.project-page-grid-container {
    margin: 0 auto;
    width: 95vw;
    
    .project-page-grid {
      border-collapse: collapse;
      margin-bottom: 20px;
      width: 100%;
    
      th,
      td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
      }
    
      th {
        background-color: #f2f2f2;
      }
    
      tr:hover {
        background-color: #f2f2f2;
        cursor: pointer;
      }
    }

    .project-page-pagination {
      display: flex;
      justify-content: center;
    
      ul {
        list-style-type: none;
        padding: 0;
        display: flex;
        gap: 5px;
      }
    
      li {
        button {
          background-color: #f2f2f2;
          border: 1px solid #ddd;
          padding: 5px 10px;
          cursor: pointer;
        }
      }
    }
}
  
  
  
  
  