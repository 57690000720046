.construction-expenses {
    .expenses-heading {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 20px;
    }
  
    .expenses-table {
      width: 100%;
      border-collapse: collapse;
  
      th,
      td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
      }
  
      th {
        background-color: #f2f2f2;
        font-weight: bold;
      }
  
      tr:nth-child(even) {
        background-color: #f2f2f2;
      }
  
      tr:hover {
        background-color: #ddd;
      }
    }
  
    p {
      font-size: 16px;
      color: #666;
      margin-top: 20px;
    }
  }

@media only screen and (max-width: 480px) {
  .construction-expenses {
    max-width: 95vw;
    .expenses-table {
      th {
        text-align: center;
      }
      th,td {
        padding: 3px;
      }
      td {
        font-size: 0.9rem;
      }

      .expenses-table-td-date {
        max-width: 85px;
      }
      .expenses-table-td-paidBy {
        max-width: 45px;
        word-wrap: break-word;
      }
      .expenses-table-td-category {
        max-width: 70px;
      }
      .expenses-table-td-amount {
        max-width: 65px;
      }
      .expenses-table-td-receivedBy {
        max-width: 65px;
      }
    }
  }
}
  