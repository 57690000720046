.projects-page-container {
    margin: 0 auto;
    width: 95vw;
    display: flex;
    flex-direction: row;
    align-items: center;

    .projects-page-container-left {
        flex: 1;
        padding: 10px 10px;

        .projects-page-container-left-table {
            border-collapse: collapse;
            width: 100%;
          
            th,
            td {
              border: 1px solid #ddd;
              padding: 8px;
              text-align: left;
            }
          
            th {
              background-color: #f2f2f2;
            }
          
            tr:hover {
              background-color: #f2f2f2;
              cursor: pointer;
            }
        }

        .projects-page-pagination {
            display: flex;
            justify-content: center;
          
            ul {
              list-style-type: none;
              padding: 0;
              display: flex;
              gap: 5px;
            }
          
            li {
              button {
                background-color: #f2f2f2;
                border: 1px solid #ddd;
                padding: 5px 10px;
                cursor: pointer;
              }
            }
          }
    }

    .projects-page-container-right {
        flex: 1;
        padding: 10px 10px;

        .fc {
            width: 100%;
            border: none;
            font-family: Arial, sans-serif;
        
            .fc-toolbar {
              margin-bottom: 20px;
              font-size: 0.75em;
              text-align: center;
            }
          }
    }
}

@media only screen and (max-width: 480px) {
  .projects-page-container {
    display: block;
  }
}
  
  
  
  
  