.grayline {
  text-align: center;
  .divider {
    height: 2px;
    background: linear-gradient(to left, white, #333, white);
  }
}

.contact-page {
    background-color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .contact-page-intro {
      margin: 0 auto;
      height: 50vh;
      width: 90vw;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      .contact-page-left {
        //width: 40%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        .contact-page-title {
          font-size: 2.5rem;
        }

        a {
          text-decoration: none;
        }
      }
      /*.contact-page-right {
        width: 60%;

        img {
          max-width: 100%;
          height: auto;
        }
      }*/
    }

    .contact-page-map {
      height: 50vh;
      iframe {
        width: 90vw;
        height: 40vh;
        border: 0;
      }
    }
  }

.blueline {
    text-align: center;
    .divider {
      height: 2px;
      background: linear-gradient(to left, white, blue, white);
    }
}

@media only screen and (max-width: 480px) {
  .contact-page {
    display: block;

    .contact-page-intro {
      height: 50vh;
      display: flex;
      flex-direction: column;

      .contact-page-left {
        height: 30vh;
        .contact-page-title {
          font-size: 1.5rem;
        }
      }
      .contact-page-right {
        height: 20vh;
        min-width: 80%;
      }
    }
    .contact-page-map {
      height: 50vh;
    }
  }
}