.authentication-error-page {
    margin: 0 auto;
    width: 95%;
    display: flex;
    flex-direction: column;
    min-height: 90vh;
  
    .error-message {
      text-align: center;
    }
  }
  