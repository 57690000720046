.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  .popup-content {
      width: 50%;
      height: 80%;
      background-color: #333;
      color: white;

      .popup-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .close-button {
              background: none;
              border: none;
              color: white;
              font-size: 3rem;
              cursor: pointer;
          }
      }

      p {
            font-size: 1.5rem;
            margin: 20px auto;
      }

      form {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: stretch;
        font-size: 1.5rem;

        .form-group-row {
            margin: 20px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: stretch;
            
            .form-group-row-column {
                input {
                    padding: 10px;
                    border-radius: 5px;
                    border: none;
                    width: 100%;
                }
            }
        }

        .submit-button {
            font-size: 1rem;
            margin: 20px auto;
            width: 50%;
            padding: 10px;
            background-color: rgb(40, 46, 117);
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s;
  
            &:hover {
                background-color: darken(blue, 10%);
            }
  
            &:disabled {
                background-color: gray;
                cursor: not-allowed;
            }
        }
      }
  }
}

@media only screen and (max-width: 480px) {
    .popup {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;

        .popup-content {
            width: 90%;
            height: 80%;
            overflow-y: auto;

            form {
                .form-group-row {
                    margin: 0;
                    display: flex;
                    flex-direction: column;
                    .form-group-row-column {
                        margin: 0.5rem;
                        input {
                            width: 95%;
                        }
                    }
                }
            }
        }
    }
}