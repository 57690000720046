// GoogleCalendarComponent.scss

.calendar-container {
  margin: 0 auto;
  width: 95vw;
  h1 {
    font-size: 1.5rem;
  }
    .fc {
      width: 100%;
      border: none;
      font-family: Arial, sans-serif;
  
      .fc-toolbar {
        margin-bottom: 20px;
        font-size: 0.75em;
        text-align: center;
      }
    }
  }
  