@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&family=Raleway&display=swap');

.header {
    margin: 10px auto;
    background-color: white;
    height: 80px;
    width: 92vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    font-family: 'Raleway', sans-serif;
    //font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: bold;

    .header-left {
      flex: 4;
      .logo {
        img {
          height: 40%;
          width: 40%;
        }
      }
    }

    .header-right {
      flex: 6;
      text-align: end;
      .header-right-bigsize {
        .nav-section {
          display: inline-block;
          margin: 0 20px;
      
          a {
            text-decoration: none;
          }
        }
        .nav-section:hover {
          box-shadow: 0 2px rgb(40, 46, 117);
        }
      }

      .menu-icon {
        display: none;
        cursor: pointer;
        font-size: 2rem;
        text-align: right;
      }
      
    }
}
@media screen and (max-width: 480px) {
  .header {
    text-align: center;
    .header-left {
      flex: 9;
      .logo img {
        height: 60%;
        width: 60%;
      }
    }
  
    .header-right {
      flex: 1;
      .menu-icon {
        display: block;
      }

      .header-right-bigsize {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 768px) {

}

.web-menu-icon {
  display: block;
  cursor: pointer;
  font-size: 1.5rem; /* Increase the font size */
  text-align: left !important; /* Align to the left side */
}
