.project-files {
    margin-top: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
  
    .files-heading {
      font-size: 1.5rem;
      margin-bottom: 15px;
      color: #333;
    }
  
    .files-list {
      display: grid;
      grid-template-columns: 1fr;
      gap: 10px;
    }
  
    .file-item {
      border-bottom: 1px solid #eee;
      padding: 10px;
      display: flex;
      align-items: center;
  
      &:last-child {
        border-bottom: none;
      }
    }
  
    .file-info {
      display: flex;
      align-items: center;
      width: 100%;
  
      .file-name {
        flex: 1;
        color: #007bff;
        text-decoration: none;
        transition: color 0.3s ease;
  
        &:hover {
          color: #0056b3;
          text-decoration: underline;
        }
      }
  
      .ai-generated {
        margin-left: 10px;
        font-size: 0.9rem;
  
        label {
          display: flex;
          align-items: center;
          cursor: not-allowed;
  
          input[type='checkbox'] {
            margin-right: 5px;
            cursor: not-allowed;
          }
        }
      }
    }
  }

@media only screen and (max-width: 480px) {
  .project-files {
    .files-list {
      .file-item {
        .file-info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .file-name {
            a {
              display: inline-block;
              word-wrap: break-word;
              max-width: 80vw;
            }
          }
          .ai-generated {
            margin: 10px 0;
          }
        }
      }
    }
  }
}
  