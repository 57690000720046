@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&family=Raleway&display=swap');

.footer {
    background-color: white;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    font-family: 'Raleway', sans-serif;
    //font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    text-align: center;

    .footer-top {
        h1 {
            font-size: 1.5rem;
            font-weight: bold;
        }
    }
    .footer-middle {
      a {
        text-decoration: none;
      }
      p {
        margin: 0;
      }
    }

    /*.footer-bottom {
      width: 90%;
      display: flex;
      justify-content: space-around;
      align-items: center;
  
      .footer-section-left {

        p {
          color: darkgray;
        }

        .footer-section-left-copyright {
          color: gray;
        }

        i {
          color: white;
          margin-left: 15px;
          font-size: 18px;
        }
      }

      .footer-section-right {
        p {
          color: darkgray;
        }

        .footer-section-right-company {
          color: white;
          font-weight: 600;
        }
      }
    }*/
    
}
  