.tasks-container {
    margin-top: 20px;

    .tasks-heading {
      font-size: 1.5rem;
      margin-bottom: 10px;
    }
    
    .tasks-table {
      width: 100%;
      border-collapse: collapse;

      th, td {
        padding: 8px;
        border-bottom: 1px solid #ccc;
      }

      th {
        text-align: left;
        font-weight: bold;
      }
      
      td {
        text-align: left;
      }
      
      tr:last-child td {
        border-bottom: none;
      }
      
      td[colspan='2'] {
        text-align: center;
        font-style: italic;
        color: #777;
      }
    }
}
  
  